<template>
  <v-card v-if="report">
    <v-card-title>Performance Report</v-card-title>
    <v-card-text>
      <div><strong>Net Profit:</strong> {{ report.net_profit }}</div>
      <div><strong>Average Drawdown:</strong> {{ report.average_drawdown }}</div>
      <div><strong>Average Drawdown Day Loss:</strong> {{ report.average_drawdown_day_loss }}</div>
      <div><strong>Max Drawdown:</strong> {{ report.max_drawdown }}</div>
      <div><strong>Net Profit / Max Drawdown:</strong> {{ report.net_profit_max_drawdown }}</div>
      <div><strong>Net Profit Std:</strong> {{ report.net_profit_std }}</div>
      <div><strong>Profit Factor:</strong> {{ report.profit_factor }}</div>
      <div><strong>% Profitable:</strong> {{ report['%_profitable'] }}</div>
      <div><strong>Avg Trade:</strong> {{ report.avg_trade }}</div>
      <div><strong>Number of Trades:</strong> {{ report.number_of_trades }}</div>
      <div><strong>Average Slippage:</strong> {{ report.average_slippage }}</div>
      <div><strong>Total Slippage:</strong> {{ report.total_slippage }}</div>
      <div><strong>Max Required Margin:</strong> {{ report.max_required_margin }}</div>
      <div><strong>Current Drawdown / Max Drawdown:</strong> {{ report.current_drawdown_max_drawdown }}</div>
      <div><strong>Distance from Lower Bollinger Band (200 days):</strong> {{ report.distance_lower_bollinger_band }}</div>
      <div><img :src="equityImageUrl" alt="Equity and Drawdown" /></div>
    </v-card-text>
  </v-card>
  <v-card v-else>
    <v-card-title>No Report Available</v-card-title>
    <v-card-text>
      <div>Il report non è disponibile o i dati sono incompleti.</div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PerformanceReport',
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  computed: {
    equityImageUrl() {
      return `http://localhost:5000/temp_files/equity_drawdown.png?${new Date().getTime()}`;
    },
  },
};
</script>

<style scoped>
</style>
