<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Notifications</h1>
        <ul>
          <li v-for="(notification, index) in notifications" :key="index">
            {{ notification }}
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'UserNotifications',
  data() {
    return {
      notifications: [
        'Notification 1',
        'Notification 2',
        'Notification 3',
      ],
    };
  },
};
</script>
