// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import UserDashboard from '../components/UserDashboard.vue';
import UserLogin from '../components/UserLogin.vue';
import UserRegister from '../components/UserRegister.vue';
import PerformanceReport from '../components/PerformanceReport.vue';
import UserNotifications from '../components/UserNotifications.vue';
import AdminDashboard from '../components/AdminDashboard.vue';

const routes = [
  { path: '/', name: 'Home', component: UserDashboard },
  { path: '/login', name: 'Login', component: UserLogin },
  { path: '/register', name: 'Register', component: UserRegister },
  { path: '/performance', name: 'Performance', component: PerformanceReport },
  { path: '/notifications', name: 'Notifications', component: UserNotifications },
  { path: '/admin', name: 'AdminDashboard', component: AdminDashboard }, // Nuova rotta
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// // Proteggi le rotte
// router.beforeEach((to, from, next) => {
//   const user = JSON.parse(localStorage.getItem('user'));

//   // Rotte che richiedono autenticazione
//   const requiresAuth = ['/performance', '/notifications', '/admin'];
//   const isAdminRoute = to.path === '/admin';

//   if (requiresAuth.includes(to.path) && !user) {
//     // Se l'utente non è loggato e la rotta richiede autenticazione, reindirizza al login
//     return next('/login');
//   }

//   if (isAdminRoute && (!user || user.role !== 'admin')) {
//     // Se la rotta è admin e l'utente non è un admin, reindirizza al login
//     return next('/login');
//   }

//   next();
// });

export default router;
