<template>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title @click="$router.push('/')">Trading App</v-toolbar-title>
  
      <v-spacer></v-spacer>
  
      <v-btn v-if="!isLoggedIn" @click="$router.push('/login')" text>Login</v-btn>
      <v-btn v-if="!isLoggedIn" @click="$router.push('/register')" text>Register</v-btn>
  
      <v-btn v-if="isLoggedIn" @click="logout" text>Logout</v-btn>
      <v-btn v-if="isLoggedIn && isAdmin" @click="$router.push('/admin')" text>Manage Users</v-btn>
    </v-app-bar>
  </template>
  
  <script>
  export default {
    computed: {
      isLoggedIn() {
        return !!localStorage.getItem('token');
      },
      isAdmin() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user && user.is_admin;
      },
    },
    methods: {
      logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        this.$router.push('/login');
      },
    },
  };
  </script>
  
  <style scoped>
  </style>
  