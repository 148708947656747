<template>
  <v-container>
    <v-form @submit.prevent="register">
      <v-text-field v-model="username" label="Username" required></v-text-field>
      <v-text-field v-model="password" label="Password" type="password" required></v-text-field>
      <v-btn type="submit" color="primary">Register</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    async register() {
      try {
        await axios.post('http://127.0.0.1:5000/api/register', {
          username: this.username,
          password: this.password,
        });

        this.$router.push('/login');
        alert('User registered successfully! Please login.');
      } catch (error) {
        console.error('Error registering user:', error);
        alert('Registration failed');
      }
    },
  },
};
</script>

<style scoped>
</style>
