<template>
  <v-container>
    <v-form @submit.prevent="login">
      <v-text-field v-model="username" label="Username" required></v-text-field>
      <v-text-field v-model="password" label="Password" type="password" required></v-text-field>
      <v-btn type="submit" color="primary">Login</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('http://127.0.0.1:5000/api/login', {
          username: this.username,
          password: this.password,
        });

        console.log('Server response:', response);

        localStorage.setItem('token', response.data.access_token);
        const user = JSON.parse(atob(response.data.access_token.split('.')[1]));
        localStorage.setItem('user', JSON.stringify(user));

        this.$router.push('/');
      } catch (error) {
        console.error('Error logging in:', error);
        alert('Invalid credentials');
        if (error.response) {
      // Il server ha risposto con uno stato diverso da 2xx
      console.error('Server responded with error:', error.response.data);
      alert(error.response.data.message || 'Login failed');
    } else if (error.request) {
      // La richiesta è stata fatta, ma non c'è stata alcuna risposta
      console.error('No response from server:', error.request);
      alert('No response from server');
    } else {
      // Qualcosa è andato storto durante l'impostazione della richiesta
      console.error('Error setting up request:', error.message);
      alert('Request setup error');
    }
      }
    },
  },
};
</script>

<style scoped>
</style>
