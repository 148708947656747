<template>
  <v-card>
      <v-card-title>Equity Line</v-card-title>
      <v-card-text>
          <canvas ref="equityLineChart"></canvas>
      </v-card-text>
  </v-card>
</template>

<script>
import { Chart, LineElement, LineController, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js';

Chart.register(LineElement, LineController, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

export default {
  name: 'EquityLine',
  props: {
      data: {
          type: Array,
          required: true,
      },
  },
  mounted() {
      this.renderChart();
  },
  methods: {
      renderChart() {
          const ctx = this.$refs.equityLineChart.getContext('2d');
          new Chart(ctx, {
              type: 'line',
              data: {
                  labels: this.data.map(trade => trade[1]),
                  datasets: [{
                      label: 'Equity Line',
                      data: this.data.map(trade => trade[2]),
                      borderColor: 'rgba(75, 192, 192, 1)',
                      borderWidth: 2,
                      fill: false,
                  }],
              },
              options: {
                  scales: {
                      x: {
                          type: 'category',
                      },
                      y: {
                          beginAtZero: true,
                      },
                  },
              },
          });
      },
  },
};
</script>

<style scoped>
</style>
