<template>
    <v-container>
      <v-card>
        <v-card-title>User Management</v-card-title>
        <v-card-text>
          <v-data-table :headers="headers" :items="users" item-key="id">
            <template v-slot>
              <v-btn @click="deleteUser(item.id)" color="red" text>Delete</v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        users: [],
        headers: [
          { text: 'ID', value: 'id' },
          { text: 'Username', value: 'username' },
          { text: 'Admin', value: 'is_admin' },
          { text: 'Actions', value: 'actions', sortable: false }, // Virgola finale aggiunta
        ],
      };
    },
    created() {
      this.fetchUsers();
    },
    methods: {
      async fetchUsers() {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get('http://127.0.0.1:5000/api/users', {
            headers: { Authorization: `Bearer ${token}` }, // Virgola finale aggiunta
          });
          this.users = response.data;
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      },
      async deleteUser(userId) {
        try {
          const token = localStorage.getItem('token');
          await axios.delete(`http://127.0.0.1:5000/api/delete_user/${userId}`, {
            headers: { Authorization: `Bearer ${token}` }, // Virgola finale aggiunta
          });
          this.fetchUsers();
          alert('User deleted successfully');
        } catch (error) {
          console.error('Error deleting user:', error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  </style>
  