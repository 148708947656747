<template>
  <v-container>
    <h1>Dashboard</h1>
    <TradingParametersForm></TradingParametersForm>
  </v-container>
</template>

<script>
import TradingParametersForm from './TradingParametersForm.vue';

export default {
  components: {
    TradingParametersForm,
  },
};
</script>

<style scoped>
</style>
