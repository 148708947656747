<template>
  <v-container>
    <v-form v-model="valid" @submit.prevent="submitForm">
      <v-select
        label="Strumento Finanziario"
        v-model="form.financial_instrument"
        :items="financialInstruments"
        required
      ></v-select>

      <v-select
        label="Condizioni di Ingresso"
        v-model="form.entry_conditions"
        :items="entryConditions"
        required
      ></v-select>

      <v-select
        label="Filtri"
        v-model="form.filters"
        :items="filters"
        required
      ></v-select>

      <v-select
        label="Condizioni di Uscita"
        v-model="form.exit_conditions"
        :items="exitConditions"
        required
      ></v-select>

      <v-number-input
        label="Stop Loss"
        v-model="form.stop_loss"
        required
      ></v-number-input>

      <v-number-input
        label="Take Profit"
        v-model="form.take_profit"
        required
      ></v-number-input>

      <v-number-input
        label="Valore Uscita"
        v-model="form.valore_uscita"
        required
      ></v-number-input>

      <v-number-input
        label="Valore Filtro"
        v-model="form.valore_filtro"
        required
      ></v-number-input>

      <v-number-input
        label="Soglia"
        v-model="form.soglia"
        required
      ></v-number-input>

      <v-divider>Backtest</v-divider>

      <v-select
        label="Timeframe"
        v-model="form.timeframe"
        :items="timeframes"
        required
      ></v-select>

      <v-date-input
        label="Data Inizio"
        v-model="form.start_date"
        required
      ></v-date-input>

      <v-date-input
        label="Data Fine"
        v-model="form.end_date"
        required
      ></v-date-input>

      <v-number-input
        label="Size"
        v-model="form.size"
        required
      ></v-number-input>

      <v-number-input
        label="Capitale Iniziale"
        v-model="form.start_capital"
        required
      ></v-number-input>

      <v-btn type="submit" color="primary">Genera Report</v-btn>
    </v-form>
<br>
    <template v-if="performanceReport">
        <PerformanceReport :report="performanceReport" />
        <EquityLine v-if="performanceReport.trades" :data="performanceReport.trades" />
        <v-card>
          <v-card-title>Grafico di Equity e Drawdown</v-card-title>
          <v-card-text>
            <div id="equityDrawdownChart"></div>
          </v-card-text>
        </v-card>
    </template>
    <template v-else>
        <v-alert type="warning" dismissible>No Report Available<br />Il report non è disponibile o i dati sono incompleti.</v-alert>
    </template>

    <v-card v-if="highlightedScript && highlightedScript !== 'No script generated.'">
        <v-card-title>Codice EasyLanguage Generato</v-card-title>
        <v-card-text>
            <pre v-html="highlightedScript"></pre>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="downloadScript" color="primary">Scarica Codice</v-btn>
        </v-card-actions>
    </v-card>
    <v-card v-else>
        <v-card-title>No EasyLanguage Script Available</v-card-title>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css';
import PerformanceReport from './PerformanceReport.vue';
import EquityLine from './EquityLine.vue';
import Plotly from 'plotly.js-dist-min';

export default {
  name: 'TradingParametersForm',
  components: {
    PerformanceReport,
    EquityLine,
  },
  data() {
    return {
      valid: false,
      form: {
        financial_instrument: 'ES=F',
        entry_conditions: 'Long',
        filters: 'Filtro 1',
        exit_conditions: 'Condizione 1',
        stop_loss: 100,
        take_profit: 200,
        valore_uscita: 5,
        valore_filtro: 10,
        soglia: 50,
        timeframe: '1min',
        start_date: new Date('2023-01-01'),
        end_date: new Date('2024-01-01'),
        size: 1,
        start_capital: 10000,
      },
      performanceReport: null,
      generatedScript: '',
      highlightedScript: '',
      equityDrawdownChart: '',
      financialInstruments: ['ES=F', 'NQ=F', 'YM=F', 'GC=F', 'CL=F'],
      timeframes: ['1min', '5min', '1h', '1d'],
      entryConditions: ['Long', 'Short'],
      filters: ['Filtro 1', 'Filtro 1b', 'Filtro 2', 'Filtro 2b',  'Filtro 3', 'Filtro 3b', 'Filtro 4', 'Filtro 4b', 'Filtro 5', 'Filtro 5b'],
      exitConditions: ['Condizione 1', 'Condizione 2'],
    };
  },
  methods: {
    async submitForm() {
    try {
        console.log('Sending form data:', this.form);
        const response = await axios.post('http://127.0.0.1:5000/api/backtest', this.form, { timeout: 30000 });
        console.log('Server response:', response.data);

        if (response.data.results && !response.data.results.error) {
            this.performanceReport = response.data.results;
            this.generatedScript = response.data.easy_language_script || 'No script generated.';
            this.highlightedScript = hljs.highlightAuto(this.generatedScript).value || 'No script generated.';

            if (response.data.chart) {
                try {
                    const chartData = JSON.parse(response.data.chart);
                    if (chartData && chartData.data) {
                        Plotly.newPlot('equityDrawdownChart', chartData.data, chartData.layout);
                    } else {
                        console.warn('No valid chart data available.');
                        this.equityDrawdownChart = '';  // Reset the chart display if no valid data
                    }
                } catch (error) {
                    console.error('Error parsing chart data:', error);
                    this.equityDrawdownChart = '';  // Handle JSON parse errors
                }
            } else {
                console.warn('No chart data available.');
                this.equityDrawdownChart = ''; // Handle no chart scenario
            }
        } else {
            console.error('No valid results returned from server');
            this.performanceReport = null;
            this.generatedScript = 'No script generated.';
            this.highlightedScript = 'No script generated.';
            this.equityDrawdownChart = '';
        }

        console.log('Form submitted successfully');
    } catch (error) {
        console.error('Error during form submission:', error);

        if (error.toJSON) {
            console.error('Error details (JSON):', error.toJSON());
        } else {
            console.error('Error details:', error.message || error);
        }

        this.performanceReport = null;
        this.generatedScript = 'No script generated due to error.';
        this.highlightedScript = 'No script generated due to error.';
        this.equityDrawdownChart = '';
    }
},
    downloadScript() {
      const blob = new Blob([this.generatedScript], { type: 'text/plain' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'easylanguage_script.txt';
      link.click();
    },
  },
};
</script>

<style scoped>
</style>